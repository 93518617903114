import { ComponentInteractionEnum } from 'enums/ComponentInteractionEnum';
import { jsonProperty, Serializable } from 'ts-serializable';
import ComponentSanfonaModel from './ComponentSanfona';
import ComponentQuizModel from './ComponentQuiz';

interface ComponentInteractionJson {
    id: number;
    tipo: ComponentInteractionEnum;
    conteudo: string;
    cor: string;
    ordem: number;
    obrigatory: boolean;
    sanfona: ComponentSanfonaModel[];
    quiz: ComponentQuizModel[];
}

class ComponentInteractionModel extends Serializable {
    @jsonProperty(Number)
    id: number = -1;

    tipo: ComponentInteractionEnum = ComponentInteractionEnum.NENHUM;

    @jsonProperty(String)
    conteudo: string = '';

    @jsonProperty(Number)
    ordem: number = -1;

    @jsonProperty(String, null)
    cor: string = '';

    @jsonProperty(Boolean, null)
    obrigatory: boolean = false;

    sanfona: ComponentSanfonaModel[] | null;

    quiz: ComponentQuizModel[] | null;

    constructor(id: number, conteudo: string, tipo: ComponentInteractionEnum, ordem: number, cor: string,  sanfona: ComponentSanfonaModel[] | null, obrigatory: boolean,  quiz: ComponentQuizModel[] | null) {
        super();
        this.id = id;
        this.conteudo = conteudo;
        this.tipo = tipo;
        this.ordem = ordem;
        this.cor = cor;
        this.sanfona = sanfona ?? null;
        this.obrigatory = obrigatory;
        this.quiz = quiz;
    }

    static fromJson(json: ComponentInteractionJson): ComponentInteractionModel {
        // Verifica se o tipo existe no enum, caso contrário, pode lançar um erro ou definir um valor padrão
        const tipo = json.tipo && Object.values(ComponentInteractionEnum).includes(json.tipo)
            ? json.tipo
            : ComponentInteractionEnum.NENHUM; // Definindo um valor padrão

        const conteudo = json.conteudo;
        const ordem = json.ordem;
        const id = json.id;
        const cor = json.cor;
        const sanfona = json.sanfona;
        const obrigatory = json.obrigatory;
        const quiz = json.quiz;

        return new ComponentInteractionModel(id, conteudo, tipo, ordem, cor, sanfona, obrigatory, quiz);
    }
}

export default ComponentInteractionModel;
