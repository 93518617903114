import Colors from 'enums/Colors';
import CapaEbook from 'pages/ebooks/components/CapaEBook';
import PageEbook from 'pages/ebooks/components/PageEbook';
import React, { useState } from 'react';
import Styled from '../../AlfaTeoriaPratica.styled';
import styled from 'styled-components';

import logoParceiros from '../../assets/AlfaTeoriaPratica/logo_parceiros_02.png';
import ContentAuthores from 'pages/ebooks/components/ContentAuthores';
import { IsCustomMaxWidth } from 'core/util/WindowSize';

const StyledAuthor = {
    Container: styled.div`
        display: flex;
        padding: 10px;
        background-color: #00a7ec;
        margin-bottom: 10px;
        padding-bottom: 42px;
    `,

    Row: styled.div`
        display: flex;
    `,

    Column: styled.div`
        display: flex;
        flex-direction: column;
    `,

    Body: styled.div`
        display: flex;
        flex-wrap: wrap;
        gap: 0.5rem;
        padding-left: 32px;
    `,

    Content: styled.div`
        width: 350px;
    `,

    ContentWhite: styled.div`
        padding: 30px;
        border-radius: 0px 30px 0px 30px;
        max-width: 600px;
        background-color: ${Colors.White};
        padding: 20px;
        line-height: 24px;
    `,
};

export interface IColumnsAuthoresEbook {
    title: string;
    subTitles: string[];
}

const column01: IColumnsAuthoresEbook[] = [
    {
        title: 'PRESIDENTE',
        subTitles: ['Viviane Senna'],
    },
    {
        title: 'VICE PRESIDENTE',
        subTitles: ['Ewerton Fulini'],
    },
    {
        title: `
        GERENTE EXECUTIVA <br />
        DO LABORATÓRIO  <br />
        DE CIÊNCIAS PARA  <br />
        EDUCAÇÃO (eduLab21®)
        `,
        subTitles: ['Gisele Alves'],
    },
    {
        title: `
        ORGANIZAÇÃO
        `,
        subTitles: ['Ana Carolina Zuanazzi', 'Maria Lúcia Voto'],
    },
    {
        title: `
        CONSELHO CIENTÍFICO <br />
        DO eduLab21®
        `,
        subTitles: ['Daniel Santos', 'Filip De Fruyt', 'Oliver P. John', 'Ricardo Primi'],
    },
];
const column02: IColumnsAuthoresEbook[] = [
    {
        title: 'AUTORES',
        subTitles: [
            'Ana Carolina Zuanazzi',
            'Ângela Naschold',
            'Beatriz Maria Campbell Alquéres',
            'Brenda Prata',
            'Daiane Zanon',
            'Gabriela Cáceres',
            'Gisele Alves',
            'Inês Kisil Miskalo',
            'Janaína Weissheimer',
            'Joana Marques de Lima Saar Xavier',
            'Mariana Luz',
            'Silvia Aparecida de Jesus Lima',
            'Vastí Ferrari Marques',
            'Wanessa Ranielle Rodrigues Trajano Costa',
            'Welen Freitas',
        ],
    },
    {
        title: 'REVISÃO',
        subTitles: [
            'Ana Carolina Zuanazzi',
            'Beatriz Maria Campbell Alquéres',
            'Débora Souza',
            'Gisele Alves',
            'Inês Kisil Miskalo',
            'Juliana Azevedo',
            'Laura Pizzo',
            'Maria Lúcia Voto',
        ],
    },
];

interface IAuthoresEbook {}

const AuthoresEbook = () => {
    return (
        <StyledAuthor.Container>
            <StyledAuthor.Column style={{ width: '100%', paddingRight: '2%' }}>
                <Styled.Row style={{ width: '100%', justifyContent: 'space-between', paddingRight: IsCustomMaxWidth(800) ? '0px' : '60px' }}>
                    <Styled.Img style={{ width: 200, height: 660, alignSelf: IsCustomMaxWidth(800) ? 'center' : 'start' }} src={logoParceiros} />
                    <StyledAuthor.Column style={{ alignItems: 'end', width: '100%' }}>
                        <Styled.ContentEnd style={{ marginRight: 32 }}>
                            <Styled.DividerYellow />
                            <Styled.TitleBlue style={{ color: Colors.White }}>INSTITUTO AYRTON SENNA</Styled.TitleBlue>
                        </Styled.ContentEnd>
                        <StyledAuthor.Body>
                            <StyledAuthor.Content>
                                {column01.map((dados, index) => (
                                    <ContentAuthores key={index} title={dados.title} subTitles={dados.subTitles} />
                                ))}
                            </StyledAuthor.Content>
                            <StyledAuthor.Content>
                                {column02.map((dados, index) => (
                                    <ContentAuthores key={index} title={dados.title} subTitles={dados.subTitles} />
                                ))}
                                <Styled.DividerYellow style={{ position: 'relative', left: '40%' }} />
                            </StyledAuthor.Content>
                        </StyledAuthor.Body>
                    </StyledAuthor.Column>
                </Styled.Row>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'end',
                        marginTop: 36,
                        gap: '1rem',
                        paddingRight: IsCustomMaxWidth(800) ? '0px' : '60px',
                    }}
                >
                    <Styled.TitleBlue style={{ fontSize: 14 }}>
                        Dados Internacionais de Catalogação na Publicação (CIP) (Câmara Brasileira do Livro, SP, Brasil)
                    </Styled.TitleBlue>

                    <StyledAuthor.ContentWhite>
                        Alfabetizar [livro eletrônico] : das definições às <br />
                        boas práticas / organização Ana Carolina Zuanazzi, <br />
                        Maria Lúcia Voto. -- <strong>São Paulo : Instituto Ayrton Senna, 2024.</strong> <br /> PDF <br /> <br />
                        Vários autores. <br />
                        ISBN 978-65-85391-20-7
                        <br /> <br />
                        1. Alfabetização 2. Educação integral 3. Letramento 4.Neurociências 5. Práticas educacionais I.Zuanazzi, Ana Carolina.
                        II. Voto, Maria Lúcia. <br /> <br />
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <span> 24-226849</span>
                            <span>CDD-372.41</span>
                        </div>
                    </StyledAuthor.ContentWhite>

                    <Styled.TitleBlue style={{ fontSize: 14, marginBottom: 10 }}>Índices para catálogo sistemático:</Styled.TitleBlue>
                    <p style={{ fontWeight: 600 }}>1. Alfabetização : Educação 372.412</p>
                    <p style={{ fontWeight: 600 }}>Eliane de Freitas Leite - Bibliotecária - CRB 8/8415</p>
                </div>
            </StyledAuthor.Column>
        </StyledAuthor.Container>
    );
};

export default AuthoresEbook;
