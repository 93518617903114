import React from 'react';
import { FaFileAudio } from 'react-icons/fa6';
import { CiText } from 'react-icons/ci';
import { GrSans } from "react-icons/gr";
import { MdFormatListNumbered } from "react-icons/md";

import { ConcluidoNaoConcluidoEnum } from 'enums/ConcluidoNaoConcluidoEnum';
import { ComponentInteractionEnum } from 'enums/ComponentInteractionEnum';


export type TListComponentesInteractions = {
    tipo: ComponentInteractionEnum;
    title: string;
    icon: any;
};

export const ListComponentesInteractions: TListComponentesInteractions[] = [
    {
        tipo: ComponentInteractionEnum.AUDIO,
        title: 'Áudio',
        icon: <FaFileAudio size={22} />,
    },
    {
        tipo: ComponentInteractionEnum.TEXT,
        title: 'Texto',
        icon: <CiText size={22} />,
    },
    {
        tipo: ComponentInteractionEnum.SANFONA,
        title: 'Sanfona',
        icon: <GrSans size={22} />,
    },
    {
        tipo: ComponentInteractionEnum.QUIZ,
        title: 'Quiz',
        icon: <MdFormatListNumbered size={22} />,
    },
];
