import DynamicHeader from 'components/dynamic_header/DynamicHeader';
import React, { useContext, useEffect } from 'react';
import Styled from './DialogosSocioemocionais.styled';
import OverviewExclusivo from '../components/OverviewExclusivo';
import logoOverview from '../assets/dialogos/LogoOverview.svg';
import ArquivoDownload from '../assets/dialogos/Dialogos_Socioemocionais_Visao_Geral.pdf';
import MateriaisExclusivos from '../components/MateriasExclusivos';
import Logo from '../assets/dialogos/logo_01.png';
import { ListMateriaisDialogosSocioemocionais } from './ListMateriaisDialogosSocioemocionais';
import CicloExclusivo from '../components/CicloExclusivo';
import ContactContainer from 'pages/home/components/contact/ContactContainer';
import PartnersExclusivo from '../components/PartnersExclusivo';
import ImagemTrilha from '../assets/dialogos/imagem_01.jpg';
import Banner01 from '../assets/dialogos/banner_01.jpg';
import Banner02 from '../assets/dialogos/banner_02.jpg';
import { AcessoExclusivoContext } from 'pages/acessoExclusivo/context/AcessoExclusivoContext';
import Loading from 'components/Loading';

const DialogosSocioemocionaisPrivate = () => {
    const { loading } = useContext(AcessoExclusivoContext.Context);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Styled.Container>
            {loading ? (
                <>
                    <Loading />
                </>
            ) : (
                <>
                    <OverviewExclusivo
                        isBlue
                        img={Banner01}
                        title="Diálogos Sócioemocionais"
                        description={`
                          
                    Um dos grandes desafios
                    atuais para os educadores e gestores é como estruturar, de forma intencional e
                    sistemática, o processo de desenvolvimento de competências socioemocionais dos
                    estudantes. Para contribuir com a construção de uma política de
                    desenvolvimento de competências socioemocionais e com a consolidação de
                    práticas pedagógicas e de gestão voltadas à educação integral, <strong> o Instituto
                    Ayrton Senna desenvolveu a proposta educacional Diálogos Socioemocionais. </strong>
                   <br /><br />
                     Focado em práticas
                    pedagógicas que possuem evidências sobre sua eficácia, o Diálogos coloca o
                    professor como mediador do desenvolvimento dos estudantes, por meio de práticas
                    SAFE, metodologias ativas, avaliação formativa e mediação dialógica, e oferece
                    conhecimento e estratégias para articular o desenvolvimento socioemocional à
                    experiência docente. A proposta disponibiliza aos gestores escolares e das
                    Secretarias de Educação uma Sistemática de Monitoramento e Acompanhamento, baseada
                    na avaliação formativa, com coleta e análise de dados e intervenções durante o
                    processo.
                  <br /><br />
                     Trata-se, portanto, de <strong>um
                    conjunto de proposições de gestão e docência articuladas e interdependentes que
                    garantem a implementação das determinações da BNCC relativas ao desenvolvimento
                    socioemocional. </strong>
                   
                `}
                        arquivo={ArquivoDownload}
                        nome_arquivo="Dialogos_Socioemocionais_Visao_Geral"
                    />
                    <CicloExclusivo
                        title="TRILHA FORMATIVA"
                        description={`
                    A trilha formativa do Diálogos Socioemocionais apresenta os conteúdos da solução educacional de modo que os profissionais 
                    (técnicos da Secretaria de Educação, gestores regionais, coordenadores pedagógicos e docentes) se apropriem dos conteúdos essenciais para a implementação da proposta.
                    Inicie agora a sua trilha formativa!
                    `}
                        img={Banner02}
                        itens={[
                            {
                                title: 'Trilha Formativa Duplo Foco',
                                url: '/recursos/dialogos-socioemocionais/dialogos-socioemocionais-duplo-foco',
                            },
                        ]}
                    />

                    <MateriaisExclusivos
                        title="ACESSE OS MATERIAIS DO DIÁLOGOS SOCIOEMOCIONAIS"
                        description={`  Tenha acesso aos materiais exclusivos do Diálogos Socioemocionais, conheça um pouco mais sobre a solução e comece a traçar os próximos passos rumo à implementação na rede.`}
                        img={Logo}
                        items={ListMateriaisDialogosSocioemocionais}
                    />
                    <PartnersExclusivo
                        title="JORNADA SOCIOEMOCIONAL DE PROFESSORES"
                        description={`
                            Você sabia que o Instituto Ayrton Senna também disponibiliza na humane uma experiência de desenvolvimento focada no Socioemocional de Professores e que você pode acessa-la?
                            <br /><br />
                  Mergulhe nessa jornada usando nossas ferramentas de Autoavaliação e Diagnóstico das habilidades específicas ao fazer docente e tenha acesso a uma Trilha de Aprendizagem com 4 cursos sobre Competências Socioemocionais de Professores. Você também terá à sua disposição uma ferramenta para construção de um Plano de Desenvolvimento Pessoal.
Aproveite mais essa oportunidade!
                    `}
                        img={ImagemTrilha}
                        button={{
                            label: 'Acesse a jornada',
                            url: '/painelSocioemocional',
                        }}
                    />
                    <ContactContainer />
                </>
            )}
        </Styled.Container>
    );
};

export default DialogosSocioemocionaisPrivate;
