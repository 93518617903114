import React, { useEffect, useState } from 'react';
import Styled from '../AdminBiblioteca.styled';

import searchIcon from '../../../../../images/biblioteca/search-icon-grey.svg';

import { OutlineButton } from 'components/UI/Buttons';
import AdminPagination from 'pages/admin/components/pagination/AdminPagination';

import AdminBiblioService from 'core/http/service/admin/AdminBiblio.service';
import { base64XLSToFile } from 'pages/admin/utils/base64ToFile';
import { DateHelp } from 'core/util/DateHelp';
import DatePicker, { registerLocale } from 'react-datepicker';

import AdminBiblioAvaliacaoModel from '../../../../../models/admin/AdminBiblioAvaliacao';
import { cloneDeep } from 'lodash';
import Loading from 'components/Loading';
import AdminBiblioGridRate from './AdminBiblioGridRate';
import BiblioItemComment from 'models/biblioteca/BiblioItemComment';
import AdminBiblioGridComment from './AdminBiblioGridComment';
import GradeAvarage from '../gradeAvarage/GrandeAvarage';
import BiblioMediaNota from 'models/biblioteca/BiblioMediaNota';
import BiblioMediaNotaService from 'core/http/service/biblioteca/BiblioMediaNota.service';
import Select, { SelectOptionType } from 'components/Select';
import BiblioItemCategoriaModel from 'models/biblioteca/BiblioItemCategoriaModel';
import BiblioConteudoService from 'core/http/service/biblioteca/BiblioConteudo.service';
import capitalize from 'pages/admin/utils/capitalize';
import ptBR from 'date-fns/locale/pt-BR';
import styled from 'styled-components';
import AdminEditorStyled from 'pages/admin/components/pageEditors/AdminEditor.styled';

export interface IAdminBiblioAvaliacao {}

export interface IFilterAvaliacao {
    ordem: string;
    tipo: string;
    nota: string;
    data: Date | undefined;
    dataFim: Date | undefined;
}

type AdminBiblioAvaliacaoSection = 'COMENTARIO' | 'NOTA' | 'MEDIANOTAS';

const AdminBiblioAvaliacao = ({}: IAdminBiblioAvaliacao) => {
    const [term, setTerm] = useState('');
    registerLocale('ptBR', ptBR);

    const [itensAvaliacao, setItensAvaliacao] = useState<AdminBiblioAvaliacaoModel[]>([]);
    const [itensComentario, setItensComentario] = useState<BiblioItemComment[]>([]);
    const [itensMediaNota, setItensMediaNota] = useState<BiblioMediaNota[]>([]);
    const [totalComments, setTotalComments] = useState(0);
    const [totalAvaliations, setTotalAvaliations] = useState(0);
    const [totalAverage, setTotalAverage] = useState(0);
    const defaultOption: SelectOptionType = { label: 'Todos', value: '' };
    const [zindexCalendar, setZindexCalendar] = useState(1);

    const [cleanKey, setCleanKey] = useState(0);

    const [page, setPage] = useState<number>(1);
    const [totalPages, setTotalPages] = useState(1);

    const [isLoading, setLoading] = useState<boolean>(false);
    const [section, setSection] = useState<AdminBiblioAvaliacaoSection>('COMENTARIO');
    const [filterDate, setFilterDate] = useState<Date | null>(null);
    const [_items, _setItems] = useState<BiblioItemCategoriaModel[]>([]);

    const [isFilter, setArrayFilter] = useState<IFilterAvaliacao>({
        ordem: '',
        tipo: '',
        nota: '',
        data: undefined,
        dataFim: undefined,
    });

    const changePage = (page: number) => {
        setPage(page);
    };

    const openCalendar = () => {
        setZindexCalendar(11);
    };

    const closeCalendar = () => {
        setZindexCalendar(1);
    };

    const optionsOrder: SelectOptionType[] = [
        {
            label: 'Selecione',
            value: '',
        },
        {
            label: 'Tipo',
            value: 't.descricao:asc',
        },
        {
            label: 'Título do conteúdo',
            value: 'i.titulo:asc',
        },
    ];

    const answeredFilter: SelectOptionType[] = [
        {
            label: 'Selecione',
            value: '',
        },
        {
            label: 'Respondido',
            value: 'true',
        },
        {
            label: 'Não Respondido',
            value: 'false',
        },
    ];

    const orderFilter: SelectOptionType[] = [
        {
            label: 'Mais recentes',
            value: 'ultima_atualizacao:desc',
        },
        {
            label: 'Nota',
            value: 'nota:desc',
        },
        {
            label: 'Tipo',
            value: 'tipo:asc',
        },
        {
            label: 'Conteúdo',
            value: 'titulo:asc',
        },
    ];

    const notasFilter: SelectOptionType[] = [
        {
            label: 'Selecione',
            value: '',
        },
        {
            label: '5',
            value: '5',
        },
        {
            label: '4',
            value: '4',
        },
        {
            label: '3',
            value: '3',
        },
        {
            label: '2',
            value: '2',
        },
        {
            label: '1',
            value: '1',
        },
        {
            label: '0',
            value: '0',
        },
    ];
    interface IFiltro {
        respondido: string | undefined;
        ordem: string | undefined;
    }

    const TipoFilter: SelectOptionType[] = [
        {
            label: 'tipo',
            value: 'tipo',
        },
    ];

    const [filtro, setFiltro] = useState<IFiltro>({
        respondido: undefined,
        ordem: undefined,
    });

    const fetchItensAvaliacao = async (filtro?: string) => {
        setLoading(true);
        const service = new AdminBiblioService();
        const { data } = await service.buscarAvaliacoes(
            page,
            filtro,
            isFilter.nota,
            isFilter.tipo,
            isFilter.data,
            isFilter.dataFim,
            isFilter.ordem
        );

        const rows = data.rows;
        setTotalPages(data.totalPages);
        const _itens: AdminBiblioAvaliacaoModel[] = rows.map((i: any) => new AdminBiblioAvaliacaoModel().fromJSON(i));
        setTotalPages(data.totalPages);
        setTotalAvaliations(data.range);
        setItensAvaliacao(_itens);
        setLoading(false);
    };

    const getOptionsCategorias = (): SelectOptionType[] => {
        return _items.map(
            (item: BiblioItemCategoriaModel) =>
                ({
                    label: capitalize(item.descricao),
                    value: item.id,
                } as SelectOptionType)
        );
    };

    const fetchItemCategoria = async () => {
        try {
            const service = new BiblioConteudoService();
            const { data } = await service.buscarCategorias(true);

            const _items: BiblioItemCategoriaModel[] = data.map((item: any) => new BiblioItemCategoriaModel().fromJSON(item));
            _setItems(_items);
        } catch (error) {
            throw error;
        } finally {
            getOptionsCategorias();
        }
    };

    const fetchItensComentarios = async () => {
        setLoading(true);
        const service = new AdminBiblioService();
        const { data } = await service.buscarComentarios(page, filtro.respondido, filtro.ordem);

        const rows = data.rows;

        const _itens: BiblioItemComment[] = rows.map((i: any) => new BiblioItemComment().fromJSON(i));
        if (section == 'COMENTARIO') setTotalPages(data.totalPages);

        setTotalComments(data.range);
        setItensComentario(_itens);
        setLoading(false);
    };

    const fetchMediaNotas = async () => {
        try {
            setLoading(true);
            const service = new BiblioMediaNotaService();
            const { data } = await service.buscarAvaliacaoMediaNota(page);

            const rows = data.rows;
            const _itens: BiblioMediaNota[] = rows.map((i: any) =>
                new BiblioMediaNota().fromJSON({
                    codigoItem: i.codigoItem,
                    tipo: i.tipo,
                    titulo: i.titulo,
                    media: Math.round(i.media),
                    total: i.total,
                })
            );
            setTotalPages(data.totalPages);
            setPage(data.page);
            setTotalAverage(data.range);
            setItensMediaNota(_itens);
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    const downloadXLS = async () => {
        const service = new AdminBiblioService();
        let response = null;

        if(section === 'COMENTARIO') {
            response = await service.downloadXLSComentarios();
        }else {
            response = await service.downloadXLSAvaliacoes();
        }

        if (response.status < 300) {
            base64XLSToFile(response.data, `avaliacoesSocioemocional-${DateHelp.formatDateFull(new Date()).replaceAll('/', '-')}.xls`);
        }
    };

    const enableComment = async (idx: number) => {
        const service = new AdminBiblioService();
        const clone = cloneDeep(itensComentario);
        clone[idx].active = !clone[idx].active;
        const { data } = await service.habilitarComentario(clone[idx].id, { comentario: clone[idx].comment, ativo: clone[idx].active });
        setItensComentario(clone);
    };

    const cleanFilterAvaliacao = () => {
        setArrayFilter({
            ordem: '',
            tipo: '',
            nota: '',
            data: undefined,
            dataFim: undefined,
        });
        setCleanKey(Math.random());
        fetchItensAvaliacao();
    };

    const cleanFilterComentario = () => {
        setFiltro({
            respondido: undefined,
            ordem: undefined,
        });
        setCleanKey(Math.random());
        fetchItensComentarios();
    };

    const CustomDatePickerInput = React.forwardRef<
        HTMLInputElement,
        React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>
    >((props, ref) => <AdminEditorStyled.Input ref={ref} {...props} />);
    CustomDatePickerInput.displayName = 'ReactDatePickerInput';

    const DatePickerWrapper = styled(({ ...props }) => (
        <DatePicker
            {...props}
            locale="ptBR"
            dateFormat="dd/MM/yyyy"
            selected={isFilter.data}
            onCalendarOpen={openCalendar}
            onCalendarClose={closeCalendar}
            onChange={(date: Date) => {
                closeCalendar();
                setArrayFilter({
                    ...isFilter,
                    data: date ?? undefined,
                });
            }}
            customInput={<CustomDatePickerInput />}
        />
    ))`
        width: 200px;
        margin-left: 5px;
    `;

    const DatePickerFimWrapper = styled(({ ...props }) => (
        <DatePicker
            {...props}
            locale="ptBR"
            dateFormat="dd/MM/yyyy"
            onCalendarOpen={openCalendar}
            onCalendarClose={closeCalendar}
            selected={isFilter.dataFim}
            onChange={(date: Date) => {
                closeCalendar();
                setArrayFilter({
                    ...isFilter,
                    dataFim: date ?? undefined,
                });
            }}
            customInput={<CustomDatePickerInput />}
        />
    ))`
        width: 200px;
        margin-left: 5px;
    `;

    useEffect(() => {
        fetchItensComentarios();
        fetchItemCategoria();
    }, []);

    useEffect(() => {
        fetchItensComentarios();
    }, [page]);

    useEffect(() => {
        section == 'NOTA'
            ? fetchItensAvaliacao()
            : section == 'MEDIANOTAS'
            ? fetchMediaNotas()
            : section == 'COMENTARIO'
            ? fetchItensComentarios()
            : '';
    }, [page, section]);

    useEffect(() => {
        fetchItensAvaliacao();
    }, [isFilter]);

    useEffect(() => {
        if (section == 'COMENTARIO') fetchItensComentarios();
    }, [filtro]);

    return (
        <>
            <Styled.Container>
                <Styled.Title>Avaliações</Styled.Title>

                <br />
                <Styled.RowSpace>
                    <Styled.SearchContainer>
                        <Styled.SearchInput placeholder="O que você está procurando?" value={term} onChange={env => setTerm(env.target.value)} />
                        <Styled.SearchIconButton src={searchIcon} onClick={() => fetchItensAvaliacao(term)} />
                    </Styled.SearchContainer>

                    <br />

                    <div>
                        <OutlineButton style={{ marginRight: '10px' }} onClick={downloadXLS}>
                            Baixar.xls
                        </OutlineButton>
                    </div>
                </Styled.RowSpace>

                <br />
                <br />
                <Styled.Row>
                    <Styled.SectionChip enabled={section == 'COMENTARIO'} onClick={() => setSection('COMENTARIO')}>
                        Comentarios
                    </Styled.SectionChip>
                    <Styled.SectionChip enabled={section == 'NOTA'} onClick={() => setSection('NOTA')}>
                        Notas
                    </Styled.SectionChip>
                    <Styled.SectionChip enabled={section == 'MEDIANOTAS'} onClick={() => setSection('MEDIANOTAS')}>
                        Média notas
                    </Styled.SectionChip>
                </Styled.Row>
                <br />

                {section == 'COMENTARIO' && (
                    <Styled.DivRowRight key={cleanKey}>
                        <Styled.RowRigth>
                            <span className="label-order">Respondido</span>
                            <span className="span-select">
                                <Select
                                    width="200px"
                                    isSmall={true}
                                    itens={answeredFilter}
                                    onSelected={o => {
                                        setFiltro({ ...filtro, respondido: o.value.toString() ?? null });
                                    }}
                                />
                            </span>
                        </Styled.RowRigth>
                        <Styled.RowRigth>
                            <span className="label-order">Ordenar por:</span>
                            <span className="span-select">
                                <Select
                                    width="200px"
                                    isSmall={true}
                                    itens={optionsOrder}
                                    defaultValue={optionsOrder[0]}
                                    onSelected={o => {
                                        setFiltro({ ...filtro, ordem: o.value.toString() ?? null });
                                    }}
                                />
                            </span>
                        </Styled.RowRigth>
                        <Styled.RowRigth>
                            <span className="label-order">Resultados encontrados: </span>
                            <span style={{ fontSize: '14px' }}>{totalComments}</span>
                        </Styled.RowRigth>
                        <Styled.RowRigth>
                            <OutlineButton onClick={cleanFilterComentario} style={{ height: '40px' }}>
                                Limpar filtros
                            </OutlineButton>
                        </Styled.RowRigth>
                    </Styled.DivRowRight>
                )}

                {section == 'NOTA' && (
                    <div key={cleanKey}>
                        <Styled.DivRowRight style={{ zIndex: 10 }}>
                            <Styled.RowRigth>
                                <span className="label-order">Ordernar por</span>
                                <span className="span-select">
                                    <Select
                                        width="200px"
                                        isSmall={true}
                                        itens={orderFilter}
                                        onSelected={o => {
                                            setArrayFilter({ ...isFilter, ordem: o.value.toString() ?? '' });
                                        }}
                                    />
                                </span>
                            </Styled.RowRigth>
                            <Styled.RowRigth>
                                <span className="label-order">Tipo</span>
                                <span className="span-select">
                                    <Select
                                        width="200px"
                                        isSmall={true}
                                        itens={[defaultOption, ...getOptionsCategorias()]}
                                        onSelected={o => {
                                            setArrayFilter({ ...isFilter, tipo: o.value.toString() ?? '' });
                                        }}
                                    />
                                </span>
                            </Styled.RowRigth>

                            <Styled.RowRigth>
                                <span className="label-order">Nota</span>
                                <span className="span-select">
                                    <Select
                                        width="200px"
                                        isSmall={true}
                                        itens={notasFilter}
                                        onSelected={o => {
                                            setArrayFilter({ ...isFilter, nota: o.value.toString() ?? '' });
                                        }}
                                    />
                                </span>
                            </Styled.RowRigth>
                        </Styled.DivRowRight>
                        <Styled.DivRowRight style={{ zIndex: zindexCalendar }}>
                            <Styled.RowRigth>
                                <span className="label-order">Data de inicio</span>
                                <DatePickerWrapper />
                            </Styled.RowRigth>
                            <Styled.RowRigth>
                                <span className="label-order">Data final</span>
                                <DatePickerFimWrapper />
                            </Styled.RowRigth>
                            <Styled.RowRigth>
                                <span className="label-order">Resultados encontrados: </span>
                                <span style={{ fontSize: '14px' }}>{totalAvaliations}</span>
                            </Styled.RowRigth>
                            <Styled.RowRigth>
                                <OutlineButton onClick={cleanFilterAvaliacao} style={{ height: '40px' }}>
                                    Limpar filtros
                                </OutlineButton>
                            </Styled.RowRigth>
                        </Styled.DivRowRight>
                    </div>
                )}
                {section == 'MEDIANOTAS' && (
                    <Styled.DivRowRight>
                        <Styled.RowRigth>
                            <span className="label-order">Resultados encontrados: </span>
                            <span style={{ fontSize: '14px' }}>{totalAverage}</span>
                        </Styled.RowRigth>
                    </Styled.DivRowRight>
                )}

                {!isLoading ? (
                    <>
                        {section === 'MEDIANOTAS' ? (
                            <GradeAvarage itens={itensMediaNota} />
                        ) : (
                            <React.Fragment>
                                <Styled.GridAvaliacao inComment={section === 'COMENTARIO'}>
                                    <Styled.GridHead>Tipo</Styled.GridHead>
                                    <Styled.GridHead>Título do conteúdo</Styled.GridHead>
                                    <Styled.GridHead>Data</Styled.GridHead>
                                    <Styled.GridHead>Autor</Styled.GridHead>
                                    <Styled.GridHead>{section == 'COMENTARIO' ? 'E-mail' : 'Nota'}</Styled.GridHead>
                                    {section == 'COMENTARIO' && <Styled.GridHead>Respondido</Styled.GridHead>}
                                    <Styled.GridHead>Ações</Styled.GridHead>

                                    {section == 'NOTA' && <AdminBiblioGridRate itens={itensAvaliacao} />}

                                    {section == 'COMENTARIO' && (
                                        <AdminBiblioGridComment
                                            updateData={fetchItensComentarios}
                                            itens={itensComentario}
                                            enableComment={enableComment}
                                            inComment={section == 'COMENTARIO'}
                                        />
                                    )}
                                </Styled.GridAvaliacao>
                            </React.Fragment>
                        )}
                    </>
                ) : (
                    <Styled.LoadingContainer>
                        <Loading />
                    </Styled.LoadingContainer>
                )}

                <br />

                <AdminPagination onChangePage={changePage} total={totalPages} />
            </Styled.Container>
        </>
    );
};

export default AdminBiblioAvaliacao;
