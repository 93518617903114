import { Alert, Button, FloatButton, GetRef, Input, message, Modal, Popconfirm, Tour, TourProps } from 'antd';
import { FcKey } from 'react-icons/fc';
import React, { useEffect, useRef, useState } from 'react';
import Cookies from 'js-cookie';
import { ToastError } from 'components/Toaster';
import keycloak from 'core/config/keycloak';
import useUserData from 'hooks/useUserData';
import { TokenPEService } from 'core/http/service/acessosExclusivos/TokenPE.service';
import TokenPE_DTO from 'models/acessoExclusivo/dtos/TokenPE_DTO';
import { stat } from 'fs';
import { useHistory } from 'react-router-dom';
import { CategoriaSolucoesPEService } from 'core/http/service/acessosExclusivos/CategoriaSolucoesPE.service';
import TipoSolucoes from 'models/solucoes/TipoSolucoes';
import { ListCards } from './MultiSolucoes';
import CartaoMultiSolucoes from './CartaoMultiSolucoes';
import Colors from 'enums/Colors';
import styled from 'styled-components';

const Styled = {
    Container: styled.div`
        display: flex;

        width: 100%;
        overflow: scroll;
        overflow-x: auto;
        overflow-y: hidden;
        background-color: ${Colors.Yellow};
        gap: 0.5rem;
        padding: 10px;
        border-radius: 10;
    `,
};

interface IResgatedToken {
    valueModalToken: boolean;
    setHidenModalToken: (value: boolean) => void;
    hidenButtonIcon?: boolean;
}

const ResgatedToken = ({ valueModalToken, setHidenModalToken, hidenButtonIcon }: IResgatedToken) => {
    const { loadData } = useUserData();
    const history = useHistory();
    const ref1 = useRef<GetRef<typeof FloatButton>>(null);
    const [messageApi, contextHolder] = message.useMessage();
    const [loading, setLoading] = useState<boolean>(false);

    const [open, setOpen] = useState<boolean>(false);
    const [openModal, setOpenModal] = useState(false);
    const [openModalConteudoExlusivo, setOpenModalConteudoExclusivo] = useState(false);
    const [token, setToken] = useState<string>('');
    const [solucoesExclusivas, setSolucoesExclusivas] = useState<TipoSolucoes[]>([]);

    const serviceCategoriaSolucoes = new CategoriaSolucoesPEService();
    const serviceToken = new TokenPEService();

    useEffect(() => {
        // Verifica se o cookie 'tourCompleted' existe
        const tourCompleted = Cookies.get('tourCompleted');
        if (!tourCompleted) {
            setOpen(true); // Se não existir, abre o tour
        }
    }, []); // Executa apenas uma vez ao montar o componente

    const steps: TourProps['steps'] = [
        {
            title: 'Continue como Parceiro do Instituto',
            description: (
                <p>
                    Digite o código da sua rede de ensino para seguir adiante e explorar os materiais da solução educacional e outros conteúdos
                    exclusivos. <br />
                    <strong>Tem dificuldades?</strong> Pergunte ao responsável da sua rede ou fale conosco para suporte.
                </p>
            ),
            target: () => ref1.current!,
            nextButtonProps: {
                children: 'Concluir', // Texto do botão "Next"
                onClick: () => handleFinish(),
            },
        },
    ];

    const success = (message: string) => {
        messageApi.open({
            type: 'success',
            content: message,
        });
    };

    const error = (message: string, delay?: number) => {
        messageApi.open({
            type: 'error',
            content: message,
            duration: delay,
        });
    };

    const handleFinish = () => {
        Cookies.set('tourCompleted', 'true', { expires: 3 });
        setOpen(false);
    };

    const handleUrl = (solucao: string) => {
        switch (solucao) {
            case 'Diálogos Socioemocionais':
                return history.push('/exclusivo/dialogos-socioemocionais');
        }
    };

    const getSolucoesUser = async () => {
        if (keycloak.authenticated) {
            const response = await serviceCategoriaSolucoes.getSolucoesByUser();

            if (response.status === 200) {
                setSolucoesExclusivas(response.data);

                if (response.data.length > 1) {
                    setOpenModalConteudoExclusivo(true);
                } else {
                    const solucao = response.data[0].nome;

                    setTimeout(() => {
                        handleUrl(solucao);
                    }, 2000);
                }
            }
        }
    };

    const handleOk = async () => {
        if (token.length === 0) return error('Campo vázio ou token inválido!');
        setLoading(true);

        const { usuarioLogado } = await loadData();

        if (usuarioLogado) {
            let tokenData = new TokenPE_DTO();
            tokenData.token = token;

            try {
                const { status, data } = await serviceToken.resgate(tokenData, usuarioLogado.username);

                if (status === 200) {
                    success('Código ativado com sucesso!');
                    getSolucoesUser();
                    setOpenModal(false);
                }

                if (status === 400) {
                    error('Você já ativou um código que está atrelado a permissão de acesso que você já possui.', 5);
                }

                if (status === 404) {
                    if (data.error === 'TOKEN_IS_INVALID') {
                        error('Você inseriu um código inválido', 5);
                    } else if (data.error === 'NOT_FIND_USER') {
                        error('Você não tem um perfil cadastrado na nossa plataforma, vamos te redirecionar para preencher o perfil.', 5);
                        setTimeout(() => {
                            history.push('/profile/informacoes');
                        }, 5000);
                    } else if (data.error === 'IS_EMPTY_CATEGORY') {
                        error('Este token não está atrelado em nenhuma permissão de acesso!', 5);
                    }
                }

                setToken('');
            } catch (error) {
                console.log('🚀 ~ handleOk ~ error:', error);
            }
        }

        setLoading(false);
        setHidenModalToken(false);
    };

    const handleCancel = () => {
        setOpenModal(false);
        setHidenModalToken(false);
        setToken('');
    };
    const handleCancelConteudoExclusivo = () => {
        setOpenModalConteudoExclusivo(false);
        window.location.reload();
    };

    useEffect(() => {
        setOpenModal(valueModalToken);
    }, [valueModalToken]);

    return (
        <>
            {contextHolder}
            <Modal
                confirmLoading={loading}
                width={'70%'}
                title={'Acesse os Conteúdos Exclusivos:'}
                open={openModalConteudoExlusivo}
                footer
                okButtonProps={{
                    disabled: true,
                }}
                cancelText="Fechar"
                onCancel={handleCancelConteudoExclusivo}
            >
                <Styled.Container>
                    {ListCards.map((dados, index) => {
                        if (solucoesExclusivas.some(obj => obj.id === dados.id)) {
                            return (
                                <div
                                    key={index}
                                    style={{
                                        display: 'flex',
                                    }}
                                >
                                    <CartaoMultiSolucoes
                                        marginTop="20px"
                                        mySolucoesExclusivas={solucoesExclusivas}
                                        dados={dados}
                                        isButtonOculto={true}
                                    />
                                </div>
                            );
                        }
                    })}
                </Styled.Container>
            </Modal>
            <Modal
                confirmLoading={loading}
                width={700}
                title={'Continue como Parceiro do Instituto'}
                open={openModal}
                okText="Ativar código de acesso"
                okButtonProps={{
                    style: {
                        backgroundColor: '#003c78',
                    },
                }}
                cancelText="Não possuo um código de acesso"
                onOk={handleOk}
                onCancel={handleCancel}
            >
                <p
                    style={{
                        fontSize: 14,
                        marginBottom: 10,
                    }}
                >
                    Digite o código da sua rede de ensino para seguir adiante e explorar os materiais da solução educacional e outros conteúdos
                    exclusivos. <br />
                    <strong>Tem dificuldades?</strong> Pergunte ao responsável da sua rede ou fale conosco para suporte.
                </p>
                <Input placeholder="Digite o código aqui." value={token} onChange={e => setToken(e.target.value)} size={'large'} />
            </Modal>
            {keycloak.authenticated && (
                <>
                    <Popconfirm
                        placement="leftTop"
                        title={'Continue como Parceiro do Instituto'}
                        description={
                            <>
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: ` Digite o código da sua rede de ensino para seguir adiante e explorar os materiais da <br /> solução educacional e outros conteúdos
                                            exclusivos. <br />
                                            <strong>Tem dificuldades?</strong> Pergunte ao responsável da sua rede ou fale conosco para suporte.
                                            <br /><br />
                                            `,
                                    }}
                                />
                                <Input
                                    style={{
                                        marginBottom: '20px',
                                    }}
                                    placeholder="Digite o código aqui."
                                    value={token}
                                    onChange={e => setToken(e.target.value)}
                                    size={'large'}
                                />
                            </>
                        }
                        okText="Ativar código de acesso"
                        open={open}
                        okButtonProps={{
                            onClick: () => {
                                handleOk();
                                handleFinish();
                            },
                        }}
                        cancelButtonProps={{
                            onClick: () => handleFinish(),
                        }}
                        cancelText="Não possuo um código de acesso"
                    >
                        {!hidenButtonIcon && (
                            <FloatButton
                                ref={ref1}
                                icon={<FcKey size={35} style={{ position: 'relative', right: 8 }} />}
                                type="primary"
                                onClick={() => setOpenModal(true)}
                                style={{
                                    insetInlineEnd: 20,
                                    insetBlockStart: 100,
                                    width: 60,
                                    height: 60,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyItems: 'center',
                                }}
                            />
                        )}
                    </Popconfirm>
                </>
            )}
        </>
    );
};

export default ResgatedToken;
