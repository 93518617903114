import { PrimaryButton, OutlineButton, NavyButton, BlueSolidButton } from 'components/UI/Buttons';
import { MobileMaxWidth } from 'constants/Dimensions';
import Colors from 'enums/Colors';
import { Form } from 'react-bootstrap';
import styled from 'styled-components';

const RecursoConteudoPageStyled = {
    Container: styled.div`
        max-width: 1280px;
        margin: 0 auto;
        margin-top: 86px;
        padding-top: 40px;

        .mobile-only {
            display: none;
        }

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            padding: 15px;

            .desk-only {
                display: none;
            }

            .mobile-only {
                display: block;
            }
        }
    `,

    Buttontext: styled.span`
        margin-left: 5px;
        transform: translateY(2px);
    `,

    Title: styled.div`
        font-family: 'Barlow';
        font-style: normal;
        font-weight: 600;
        font-size: 32px;
        color: #003c78;
        margin: 30px 0;
    `,

    ColumnContent: styled.div`
        width: fit-content;
    `,

    PlaylistItemCheck: styled.span<{ show: boolean }>`
        margin-right: 10px;
        opacity: ${props => (props.show ? '1' : '0')};
        transform: translateY(-5px);
    `,

    ImageContainer: styled.div`
        width: 100%;
        height: 430px;
        background-color: #d9d9d9;
        border-radius: 5px;
        margin-bottom: 15px;
        overflow: hidden;

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            width: 100%;
        }
    `,

    Image: styled.img`
        width: 100%;
        height: 100%;
        object-fit: cover;
    `,

    Gap: styled.div`
        width: 8rem;

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            display: none;
        }
    `,

    SpaceHorizontal: styled.div`
        flex-grow: 1;
    `,

    PlaylistContainer: styled.div`
        width: 495px;
        background-color: #1d5894;
        max-height: 430px;
        overflow-y: auto;
        margin-bottom: 15px;
    `,

    PlaylistItem: styled.div<{ active: boolean }>`
        border-bottom: 1px #558cc2 solid;
        background-color: ${props => (!props.active ? '#1D5894' : '#003C78')};
        color: #fff;
        cursor: pointer;
        padding: 15px 25px;
        padding-left: 15px;

        :hover {
            background-color: ${props => (props.active ? '#003C78' : '#0f4a86')};
        }
    `,

    SquareButton: styled(NavyButton)`
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: #00a8e9;
        color: white;
        border: none;
        font-weight: 400;
        font-size: 1.5rem;
        width: 60px;
        height: 60px;
        border-radius: 7px;

        label {
            display: none;
            cursor: pointer;
        }

        span {
            margin-left: 5px;
        }

        @media screen and (min-width: ${MobileMaxWidth}px) {
            label {
                display: flex;
                font-size: 12px;
                margin-top: 3px;
            }
        }

        :hover {
            background-color: #47aefc;
        }

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            width: 46px;
            height: 46px;
            border-radius: 23px;
            margin: 0 10px;
        }
    `,

    TextProgress: styled.label`
        color: ${Colors.DarkBlue};
        margin-bottom: 10px;
        font-weight: bold;
    `,

    MarkButton: styled(PrimaryButton)`
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 60px;
        font-size: 19px;
        margin-bottom: 20px;

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            font-size: 16px;
            margin-bottom: 0;
        }
    `,

    ButtonContainer: styled.div`
        max-width: 500px;
        display: flex;
    `,

    DoneButton: styled(PrimaryButton)`
        width: 100%;
        height: 60px;
        font-size: 19px;
        margin-bottom: 20px;
        background-color: #003c78;
        border: 1px solid #003c78;
        color: white;

        span {
            margin-left: 10px;
        }

        :hover {
            color: white;
        }

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            font-size: 16px;
            margin-bottom: 0;
        }
    `,

    AvancarButton: styled(PrimaryButton)`
        width: 100%;
        height: 60px;
        font-size: 19px;
        margin-bottom: 20px;
        border: 1px solid #00aaea;
        color: #003c78;
        background-color: #00aaea;

        span {
            margin-left: 10px;
        }

        :hover {
            color: white;
        }

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            font-size: 16px;
            margin-bottom: 0;
        }
    `,

    NextButton: styled(OutlineButton)`
        width: 42px;
        height: 42px;
        border-radius: 5px;
    `,

    SendComentary: styled(BlueSolidButton)<{ disabled: boolean }>`
        background-color: ${({ disabled }) => disabled && Colors.Grey10};

        &:hover {
            background-color: ${({ disabled }) => disabled && Colors.Grey10};
            color: ${({ disabled }) => disabled && '#FFFFFF'};
            border: ${({ disabled }) => disabled && 'none'};
            cursor: ${({ disabled }) => disabled && 'auto'};
        }
    `,

    TextContent: styled.p`
        max-width: 680px;
        margin: 40px 0px;
        line-height: 160%;

        ol,
        ul {
            list-style: inside;
        }

        h1,
        h2 {
            font-weight: 700;
        }
        h1 {
            font-size: 2em;
        }
        h2 {
            font-size: 1.5em;
        }
        h3 {
            font-size: 1.17em;
        }
        h4 {
            font-size: 1em;
        }
        h5 {
            font-size: 0.8em;
        }
        h6 {
            font-size: 0.6em;
        }
        iframe {
            width: 100%;
            min-height: 300px;
        }
        img {
            width: 100%;
            cursor: pointer;
        }
    `,

    Subtitle: styled.p`
        font-family: 'Barlow';
        font-style: normal;
        font-weight: 600;
        font-size: 32px;
        line-height: 38px;
        color: #003c78;
    `,
    Label: styled.p`
        font-family: Barlow;
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        color: #003c78;
    `,

    CommentBox: styled.div<{ isDropped: boolean }>`
        display: flex;
        flex-direction: column;
        max-height: ${props => (props.isDropped ? '100%' : '210px')};
        overflow: hidden;
        position: relative;
        margin-bottom: 30px;
    `,

    FormControl: Form.Control,

    SeeMoreButton: styled.button<{ isDropped: boolean }>`
        font-family: 'Barlow';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        color: #003c78;

        background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 62.29%);
        height: 90px;
        width: 100%;
        left: 0;
        bottom: ${props => (props.isDropped ? '20px' : '0')};
        border: none;
        position: absolute;
        display: flex;
        align-items: flex-end;
        justify-content: center;
    `,

    Icon: styled.img`
        height: 0.9rem;
        transform: translate(-8px, -2px);
    `,

    AlignRight: styled.div`
        text-align: right;
        margin-top: 10px;
    `,

    RoundPrimaryButton: styled(PrimaryButton)`
        width: 40px;
        height: 40px;
    `,

    MediaContainer: styled.div`
        width: 100%;
    `,

    ButtonPDF: styled.button`
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 16px 24px;
        gap: 8px;
        width: 291px;
        height: 67px;
        margin-bottom: 40px;
        border-radius: 6px;
        border: none;
        background-color: ${Colors.BrightGreen};
        color: ${Colors.White};

        font-family: 'Barlow';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;

        letter-spacing: -0.01em;
        font-feature-settings: 'calt' off;

        &:hover {
            box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.25);
        }
    `,

    AudioContainer: styled.div`
        audio {
            width: 100%;
        }

        audio::-webkit-media-controls-panel {
            background-color: #bfbfbf;
        }
    `,
};

export default RecursoConteudoPageStyled;
