import { useKeycloak } from '@react-keycloak/web';
import PopupQuestion from 'components/popupQuestion/PopupQuestion';
import { PrimaryButton } from 'components/UI/Buttons';
import { ProfileService } from 'core/http/service/Profile.service';
import { getUserRoleForProfile, isAutoCadastrado, isUsuarioLms } from 'core/util/KeycloakHelp';
import { MaskDate } from 'core/util/MaskHelper';
import userOccupations, { UserOccupation } from 'data/userOccupations';
import { RoleEnum } from 'enums/RoleEnum';
import { Formik, FormikProps } from 'formik';
import { useAppSelector } from 'hooks/LocalReduxThunk';
import { cloneDeep } from 'lodash';
import ParametrosCadastroPerfilDTO from 'models/perfil/ParametrosCadastroPerfilDTO';
import ProfileData from 'models/ProfileData';
import UsuarioDTO from 'models/UsuarioDTO';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import * as authenticationActions from '../../../../../store/actions/Authentication';
import * as profileActions from '../../../../../store/actions/Profile';
import FormikReactSelect from '../../formik/FormikReactSelect';
import FormikTextInput from '../../formik/FormikTextInput';
import Styled from '../InformacoesPessoais.styled';
import { cpf } from 'cpf-cnpj-validator';
import { Modal } from 'react-bootstrap';
import { IsMobileMaxWidth } from 'core/util/WindowSize';
import TipoDeficiencia from 'models/perfil/TipoDeficiencia';
import { DeficienciasService } from 'core/http/service/Deficiencias.service';
import TipoDeficienciaDTO from 'models/perfil/TipoDeficienciaDTO';
import { BlueButton } from 'pages/intermediarias/criatividadeDetail/versions/components/sections/Material.styled';

export interface IInformacoesPessoaisForm1 {}

const InformacoesPessoaisForm1 = ({}: IInformacoesPessoaisForm1) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { keycloak } = useKeycloak();
    const profileService = new ProfileService();
    const deficienciasService = new DeficienciasService();
    const [submitting, setSubmitting] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [tiposDeficiencias, setTiposDeficiencias] = useState<TipoDeficiencia[]>([]);
    const [selectedDeficiencias, setSelectedDeficiencias] = useState<TipoDeficienciaDTO[]>([]);
    const [selectedPossuiDeficiencia, setSelectedPossuiDeficiencia] = useState<number>(-1);
    const profileData: ProfileData | null = useAppSelector(state => state.profileReducer.profileData);
    const usuarioLogado: UsuarioDTO = useAppSelector(state => state.authenticationReducer.usuarioLogado);
    const profileParameterData: ParametrosCadastroPerfilDTO = useAppSelector(state => state.profileReducer.parametrosCadastro);
    const [usOc, setUsOc] = useState<UserOccupation[]>(userOccupations);
    const [ showDialog, setShowDialog] = useState<boolean>(false);
    const [isDeficiente, setIsDeficiente] = useState<boolean>(false);

    const [otherDeficiencia, setOtherDeficiencia] = useState<boolean>(false);

    const listIsDeficiencia = [
        { id: 1, nome: 'Sim', value: false },
        { id: 2, nome: 'Não', value: false },
    ];

    const userRoles = (): RoleEnum[] => {
        return getUserRoleForProfile(usuarioLogado.roles);
    };

    const isUserPreRegistered = (): boolean => {
        return userRoles().length > 0;
    };

    const roleDescription = (): string => {
        return usOc.find(o => o.role == userRoles()[0])?.descricao ?? '';
    };

    const handleCheckboxChange = (deficiencia: TipoDeficiencia, isChecked: boolean) => {
        const tipoDeficienciaInstance = new TipoDeficienciaDTO();
        tipoDeficienciaInstance.codigoTipoDeficiencia = deficiencia.id;

        if (isChecked) {
            setSelectedDeficiencias(
                prevSelected =>
                    prevSelected.filter(
                        selected => selected.codigoTipoDeficiencia !== tipoDeficienciaInstance.codigoTipoDeficiencia
                    ) as TipoDeficienciaDTO[]
            );
        } else {
            setSelectedDeficiencias(prevSelected => [...prevSelected, tipoDeficienciaInstance]);
        }

        if (tipoDeficienciaInstance.codigoTipoDeficiencia === 7 && !isChecked) {
            setOtherDeficiencia(true);
        } else if (tipoDeficienciaInstance.codigoTipoDeficiencia === 7 && isChecked) {
            setOtherDeficiencia(false);
        }
    };

    const [otherOccupation, setOtherOccupation] = useState<boolean>(false);

    const initialErrors = {
        erroCpf: '',
        erroComoConheceu: '',
        erroDataNascimento: '',
        erroGenero: '',
        erroCor: '',
        erroTipoFormacao: '',
        erroOcupacao: '',
        erroOcupacaoOutra: '',
        erroSeledtedDeficiencia: '',
        erroDeficiencia: '',
        erroDeficienciaOutra: '',
    };

    const [erros, setErros] = useState(initialErrors);

    const OTHER_OCCUPATION_ID = 99;

    interface IValues {
        //Form 1
        cpf: string;
        comoConheceu: number;
        dataNascimento: string;
        genero: number;
        cor: number;
        tipoFormacao: number;
        ocupacao: string;
        ocupacaoOutra: string;
        selectedDeficiencia: number;
        deficiencia: TipoDeficienciaDTO[];
        deficienciaOutra: string;
    }

    let formikInitial: IValues = {
        cpf: profileData?.cpf ?? '',
        comoConheceu: profileData?.comoConheceu ?? 0,
        dataNascimento: profileData?.dataNascimento ?? '',
        genero: profileData?.genero ?? 0,
        cor: profileData?.cor ?? 0,
        tipoFormacao: profileData?.tipoFormacao ?? 0,
        ocupacao: roleDescription() ?? profileData?.ocupacao ?? '',
        ocupacaoOutra: profileData?.outraOcupacao ?? '',
        deficiencia: profileData?.tipoDeficiencia ?? [],
        selectedDeficiencia: selectedPossuiDeficiencia ?? -1,
        deficienciaOutra: profileData?.tipoDeficiencia.find(f => f.codigoTipoDeficiencia === 7)?.descricao || '',
    };

    const handleDescription = (value: string) => {
        const novaLista: TipoDeficienciaDTO[] = selectedDeficiencias.map((obj: TipoDeficienciaDTO) => {
            if (obj.codigoTipoDeficiencia === 7) {
                return { ...obj, descricao: value };
            }
            return obj;
        }) as TipoDeficienciaDTO[];

        setSelectedDeficiencias(novaLista);
    };

    const onSubmitHandler = async (e: any, formik: FormikProps<IValues>): Promise<any> => {
        e.preventDefault();
        const values = formik.values;

        if (!isValid(formik)) {
            return updateErrors(values);
        }

        setSubmitting(true);
        const cloneData = profileData == null ? new ProfileData() : cloneDeep(profileData);

        cloneData.comoConheceu = values.comoConheceu;
        cloneData.dataNascimento = values.dataNascimento;
        cloneData.genero = values.genero;
        cloneData.cor = values.cor;
        cloneData.tipoFormacao = values.tipoFormacao;
        cloneData.outraOcupacao = values.ocupacaoOutra;
        cloneData.username = usuarioLogado?.username;
        cloneData.tipoDeficiencia = selectedDeficiencias;
        cloneData.cpf = isUsuarioLms(usuarioLogado) ? values.cpf : usuarioLogado?.username;

        let occupation = usOc.find(o => o.id == parseInt(values.ocupacao));

        if (isUserPreRegistered()) occupation = usOc.find(o => o.role == userRoles()[0]);

        cloneData.ocupacao = occupation?.descricao ?? values.ocupacao;

        if (occupation?.id == OTHER_OCCUPATION_ID) {
            cloneData.tipoPerfil = RoleEnum.Outro;

            cloneData.ocupacao = values.ocupacaoOutra;

            await dispatch(profileActions.storeProfileData(cloneData));

            history.push('/profile/informacoes/2');

            setSubmitting(false);
        } else {
            cloneData.tipoPerfil = occupation?.role ?? RoleEnum.Professor;
            await dispatch(profileActions.storeProfileData(cloneData));
            setSubmitting(false);

            history.push('/profile/informacoes/2');
        }
    };

    const fetchDeficiencias = async () => {
        const { data } = await deficienciasService.listarAll();

        if (data) setTiposDeficiencias(data);
    };

    const isValid = (formik: FormikProps<IValues>) => {
        const values = formik.values;

        if (!validDate(values.dataNascimento)) return false;

        if (isUsuarioLms(usuarioLogado) && !cpf.isValid(values.cpf)) return false;

        const deficienciaOutra = selectedDeficiencias.find(selected => selected.codigoTipoDeficiencia === 7);

        if (values.comoConheceu == 0) return false;
        if (values.genero == 0) return false;
        if (values.cor == 0) return false;
        if (values.tipoFormacao == 0) return false;
        if (values.ocupacao == '') return false;
        if (values.selectedDeficiencia == -1) return false;
        if (values.selectedDeficiencia === 1 && selectedDeficiencias.length === 0) return false;
        if (values.selectedDeficiencia === 1 && deficienciaOutra && values.deficienciaOutra.length < 5) return false;

        const occupation = usOc.find(o => o.id === parseInt(values.ocupacao.toString()));
        if (occupation?.id == OTHER_OCCUPATION_ID && (values.ocupacaoOutra == '' || values.ocupacaoOutra.length < 4)) return false;

        if (!validOutraOcupacao(values.ocupacaoOutra)) return false;

        return true;
    };

    const validDate = (stringDate: string): boolean => {
        const re = /\d{2}\/\d{2}\/\d{4}/g;
        const [d, m, y] = stringDate.split('/');
        const date = moment(`${y}-${m}-${d}`);

        if (!re.test(stringDate) || !date.isValid() || date.diff(new Date(), 'day') >= 0) return false;

        return true;
    };

    const validOutraOcupacao = (stringText: string): boolean => {
        if (stringText.length <= 3) return true;

        const re = /^[A-Z\s]+$/i;
        return re.test(stringText);
    };

    const updateErrors = (values: IValues) => {
        const _errors = {
            erroCpf: isUsuarioLms(usuarioLogado) && !cpf.isValid(values.cpf) ? 'CPF Inválido' : '',
            erroComoConheceu: values.comoConheceu == 0 ? 'Campo não preenchido' : '',
            erroDataNascimento: !validDate(values.dataNascimento) ? 'Data de nascimento inválida' : '',
            erroGenero: values.genero == 0 ? 'Campo não preenchido' : '',
            erroCor: values.cor == 0 ? 'Campo não preenchido' : '',
            erroTipoFormacao: values.tipoFormacao == 0 ? 'Campo não preenchido' : '',
            erroOcupacao: values.ocupacao == '' ? 'Campo não preenchido' : '',
            erroSeledtedDeficiencia: values.selectedDeficiencia == -1 ? 'Campo não preenchido' : '',
            erroDeficiencia: values.deficiencia.length == 0 && values.selectedDeficiencia === 1 ? 'Campo não preenchido' : '',
            erroOcupacaoOutra: '',
            erroDeficienciaOutra: '',
        };

        const occupation = usOc.find(o => o.id === parseInt(values.ocupacao.toString()));

        _errors.erroOcupacaoOutra =
            occupation?.id == OTHER_OCCUPATION_ID && (values.ocupacaoOutra == '' || values.ocupacaoOutra.length < 4)
                ? 'Campo não preenchido ou insuficiente'
                : '';

        setErros(_errors);
    };

    const clearError = () => setErros(initialErrors);

    const getSomeError = (values: IValues) => {
        const {
            erroCpf,
            erroComoConheceu,
            erroDataNascimento,
            erroGenero,
            erroCor,
            erroTipoFormacao,
            erroOcupacao,
            erroOcupacaoOutra,
            erroDeficiencia,
            erroDeficienciaOutra,
            erroSeledtedDeficiencia,
        } = erros;

        const value =
            !!erroCpf ||
            !!erroComoConheceu ||
            !!erroDataNascimento ||
            !!erroGenero ||
            !!erroCor ||
            !!erroTipoFormacao ||
            !!erroOcupacao ||
            !!erroDeficiencia ||
            !!erroDeficienciaOutra ||
            !!erroSeledtedDeficiencia ||
            !!erroOcupacaoOutra;
        if (
            value == true &&
            !erroCpf &&
            !erroComoConheceu &&
            !erroGenero &&
            !erroCor &&
            !erroTipoFormacao &&
            !erroOcupacao &&
            !erroDeficiencia &&
            !erroDeficienciaOutra &&
            !erroSeledtedDeficiencia &&
            !erroOcupacaoOutra
        ) {
            if (values.dataNascimento.length == 0) return true;
            return validDate(values.dataNascimento);
        }

        return value;
    };

    const handleAccess = () => {
        window.open('https://humaneias.zendesk.com/hc/pt-br/requests/new');
    };

    useEffect(() => {
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 300);
    }, []);

    useEffect(() => {
        fetchDeficiencias();
    }, []);

    const Tooltip = () => {
        return (
            <Styled.TooltipStyle>
                <Styled.InfoText>{`Por que pedimos essa ${` `} 
                            informação?`}</Styled.InfoText>
                <Styled.Tooltipdialog className="tooltipdialog">
                    {`O CPF é importante para  vincularmos todos os produtos do Instituto a um único cadastro.
                        ${` `}
                        Ele não será compartilhado com organizações externas.`}
                </Styled.Tooltipdialog>
            </Styled.TooltipStyle>
        );
    };

    const QuestionMark = () => {
        return (
            <Styled.QuestionMarkStyle
                onClick={() => {
                    setShowDialog(true);
                }}
            >
                <Styled.SvgIcon>
                    <path
                        d="M8.17248 5.3494C7.86213 5.29616 7.54296 5.35448 7.27149 5.51403C7.00003 5.67357 6.79378 5.92404 6.68929 6.22108C6.56711 6.56841 6.1865 6.75092 5.83917 6.62874C5.49185 6.50656 5.30933 6.12595 5.43151 5.77862C5.64049 5.18455 6.05298 4.68361 6.59592 4.36452C7.13885 4.04543 7.7772 3.92879 8.39789 4.03526C9.01859 4.14172 9.58157 4.46442 9.98714 4.9462C10.3926 5.42789 10.6146 6.0375 10.6137 6.66713C10.6134 7.68736 9.85703 8.36111 9.31687 8.72122C9.02645 8.91483 8.74077 9.0572 8.53033 9.15073C8.42416 9.19791 8.33476 9.23372 8.27034 9.25826C8.23807 9.27055 8.21191 9.28008 8.19287 9.28685L8.16972 9.29496L8.16234 9.29748L8.15974 9.29835L8.15872 9.29869C8.15872 9.29869 8.15789 9.29897 7.94707 8.66652L8.15789 9.29897C7.80859 9.41541 7.43105 9.22663 7.31461 8.87734C7.19826 8.52827 7.3867 8.151 7.73555 8.0343L7.74619 8.03056C7.75645 8.02692 7.77325 8.02082 7.79568 8.01228C7.84063 7.99515 7.90748 7.96846 7.98881 7.93231C8.15337 7.85917 8.36769 7.75154 8.57727 7.61182C9.037 7.30533 9.2804 6.97927 9.2804 6.66652L9.2804 6.66553C9.28087 6.35065 9.16989 6.04576 8.9671 5.80487C8.76432 5.56398 8.48283 5.40263 8.17248 5.3494Z"
                        fill="#5F758B"
                    />
                    <path
                        d="M8.00033 10.6665C7.63213 10.6665 7.33366 10.965 7.33366 11.3332C7.33366 11.7014 7.63213 11.9998 8.00033 11.9998H8.00699C8.37518 11.9998 8.67366 11.7014 8.67366 11.3332C8.67366 10.965 8.37518 10.6665 8.00699 10.6665H8.00033Z"
                        fill="#5F758B"
                    />
                    <path
                        d="M0.666992 7.99984C0.666992 3.94975 3.95024 0.666504 8.00033 0.666504C12.0504 0.666504 15.3337 3.94975 15.3337 7.99984C15.3337 12.0499 12.0504 15.3332 8.00033 15.3332C3.95024 15.3332 0.666992 12.0499 0.666992 7.99984ZM8.00033 1.99984C4.68662 1.99984 2.00033 4.68613 2.00033 7.99984C2.00033 11.3135 4.68662 13.9998 8.00033 13.9998C11.314 13.9998 14.0003 11.3135 14.0003 7.99984C14.0003 4.68613 11.314 1.99984 8.00033 1.99984Z"
                        fill="#5F758B"
                    />
                </Styled.SvgIcon>
            </Styled.QuestionMarkStyle>
        );
    };

    const InfoDialog = () => {
        return (
            <>
                <Modal
                    show={showDialog}
                    onHide={() => {
                        setShowDialog(false);
                    }}
                    backdrop="static"
                    keyboard={false}
                    dialogAs={Styled.ModalContainer}
                >
                    <Modal.Body>
                        <Styled.InfoDialogTitle>{`Por que pedimos essa informação?`}</Styled.InfoDialogTitle>
                        <br />
                        <Styled.InfoDialogDescription>{`O CPF é importante para  vincularmos todos os produtos do Instituto a um único cadastro.`}</Styled.InfoDialogDescription>
                        <br />
                        <Styled.InfoDialogDescription>{`Ele não será compartilhado com organizações externas.`}</Styled.InfoDialogDescription>
                        <Styled.InofoDialogButton
                            onClick={() => {
                                setShowDialog(false);
                            }}
                        >
                            Continuar Cadastro
                        </Styled.InofoDialogButton>
                    </Modal.Body>
                </Modal>
            </>
        );
    };
    return (
        <Styled.Container>
            <Styled.Title>Informações pessoais</Styled.Title>
            <Styled.Subtitle>Nos conte um pouco mais sobre você!</Styled.Subtitle>
            <br />
            <br />

            <Formik initialValues={formikInitial} onSubmit={() => {}}>
                {formik => {
                    useEffect(() => clearError(), [formik.values]);

                    return (
                        <form>
                            {/*Quando for usuário do lms solicita cpf */}
                            {isUsuarioLms(usuarioLogado) ? (
                                <>
                                    <Styled.Row error={!!erros.erroCpf}>
                                        <Styled.Label error={!!erros.erroCpf}>Meu CPF é</Styled.Label>
                                        <FormikTextInput
                                            validateCpf={true}
                                            maxLength={14}
                                            size={265}
                                            name="cpf"
                                            placeholder="Seu CPF"
                                            formik={formik}
                                            onBlurHandler={() => {}}
                                            onChangeHandler={(evt: any) => {
                                                const value: string = evt.target.value;
                                                formik.setFieldValue('cpf', value);
                                            }}
                                        />

                                        {IsMobileMaxWidth() ? <QuestionMark /> : <Tooltip />}
                                        {erros.erroCpf && <Styled.ErrorText2>Cpf Inválido</Styled.ErrorText2>}
                                    </Styled.Row>
                                </>
                            ) : (
                                <></>
                            )}

                            <Styled.Row error={!!erros.erroComoConheceu}>
                                <Styled.Label error={!!erros.erroComoConheceu}>Como conheceu a humane?</Styled.Label>
                                <FormikReactSelect
                                    size={330}
                                    name="comoConheceu"
                                    key={`comoConheceu-${erros.erroComoConheceu}`}
                                    options={profileParameterData.listaComoConheceu}
                                    formik={formik}
                                    onChangeHandler={async (value: number) => {}}
                                    error={!!erros.erroComoConheceu}
                                />
                            </Styled.Row>

                            <Styled.RowNoMargin style={{ marginTop: '-8px' }}>
                                <Styled.Row error={!!erros.erroDataNascimento}>
                                    <Styled.Label error={!!erros.erroDataNascimento}>Data de nascimento</Styled.Label>
                                    <FormikTextInput
                                        size={275}
                                        name="dataNascimento"
                                        placeholder="DD/MM/AAAA"
                                        formik={formik}
                                        onBlurHandler={() => {}}
                                        onError={formik.values.dataNascimento.length == 10 && !!erros.erroDataNascimento}
                                        onChangeHandler={(evt: any) => {
                                            const value: string = evt.target.value;
                                            const newDate = formik.values['dataNascimento'].endsWith('/') ? value : MaskDate(value);
                                            formik.setFieldValue('dataNascimento', newDate);
                                        }}
                                    />
                                </Styled.Row>
                                {formik.values.dataNascimento.length == 10 && !!erros.erroDataNascimento && (
                                    <Styled.ErrorText2>Data inválida</Styled.ErrorText2>
                                )}
                            </Styled.RowNoMargin>

                            <Styled.Row error={!!erros.erroGenero} style={{ marginTop: '-1px' }}>
                                <Styled.Label error={!!erros.erroGenero}>Gênero</Styled.Label>
                                <FormikReactSelect
                                    size={250}
                                    name="genero"
                                    key={`genero-${erros.erroGenero}`}
                                    options={profileParameterData.listaGeneros}
                                    formik={formik}
                                    onChangeHandler={async (value: number) => {}}
                                    error={!!erros.erroGenero}
                                />
                            </Styled.Row>

                            <Styled.Row error={!!erros.erroCor}>
                                <Styled.Label error={!!erros.erroCor}>Cor</Styled.Label>
                                <FormikReactSelect
                                    size={220}
                                    name="cor"
                                    key={`cor-${erros.erroCor}`}
                                    options={profileParameterData.listaCores}
                                    formik={formik}
                                    onChangeHandler={async (value: number) => {}}
                                    error={!!erros.erroCor}
                                />
                            </Styled.Row>

                            <Styled.Row error={!!erros.erroTipoFormacao}>
                                <Styled.Label error={!!erros.erroTipoFormacao}>Maior nível de escolaridade</Styled.Label>
                                <FormikReactSelect
                                    size={350}
                                    name="tipoFormacao"
                                    key={`tipoFormacao-${erros.erroTipoFormacao}`}
                                    options={profileParameterData.listaTipoFormacao}
                                    formik={formik}
                                    onChangeHandler={async (value: number) => {}}
                                    error={!!erros.erroTipoFormacao}
                                />
                            </Styled.Row>
                            <Styled.Row error={!!erros.erroOcupacao}>
                                <Styled.Label error={!!erros.erroOcupacao}>Principal ocupação</Styled.Label>
                                {isUserPreRegistered() ? (
                                    <Styled.Answer>
                                        <span>{usOc.find(o => o.role == userRoles()[0])?.descricao ?? ''}</span>
                                    </Styled.Answer>
                                ) : (
                                    <FormikReactSelect
                                        size={220}
                                        name="ocupacao"
                                        key={`ocupacao-${erros.erroOcupacao}`}
                                        options={userOccupations.filter(o => o.autoCadastravel === true)}
                                        formik={formik}
                                        onChangeHandler={async (value: number) => {
                                            setOtherOccupation(value === OTHER_OCCUPATION_ID);
                                        }}
                                        error={!!erros.erroOcupacao}
                                    />
                                )}
                            </Styled.Row>
                            {otherOccupation && (
                                <Styled.Row error={!!erros.erroOcupacaoOutra}>
                                    <Styled.Label error={!!erros.erroOcupacaoOutra}>Identifique a ocupação</Styled.Label>
                                    <FormikTextInput
                                        size={455}
                                        name="ocupacaoOutra"
                                        key={`ocupacaoOutra-${erros.erroOcupacaoOutra}`}
                                        placeholder="Insira"
                                        formik={formik}
                                        onBlurHandler={() => {}}
                                        onChangeHandler={(evt: any) => {
                                            formik.setFieldValue('ocupacaoOutra', evt.target.value);
                                        }}
                                        onError={!validOutraOcupacao(formik.values.ocupacaoOutra)}
                                    />
                                </Styled.Row>
                            )}
                            <Styled.Row error={!!erros.erroSeledtedDeficiencia}>
                                <Styled.Label error={!!erros.erroSeledtedDeficiencia}>Você possui algum tipo de Deficiência?</Styled.Label>

                                <FormikReactSelect
                                    size={220}
                                    name="selectedDeficiencia"
                                    key={`selectedDeficiencia-${erros.erroSeledtedDeficiencia}`}
                                    options={listIsDeficiencia}
                                    formik={formik}
                                    onChangeHandler={async (value: number) => {
                                        setIsDeficiente(value === 1);
                                        setSelectedPossuiDeficiencia(value);
                                    }}
                                    error={!!erros.erroSeledtedDeficiencia}
                                />
                            </Styled.Row>
                            {isDeficiente && (
                                <Styled.Column error={!!erros.erroDeficiencia}>
                                    <Styled.Label error={!!erros.erroDeficiencia}>Informe qual o tipo de deficiência você possui:</Styled.Label>

                                    <Styled.TwoColumns>
                                        {tiposDeficiencias.map((e, idx) => {
                                            const isChecked = selectedDeficiencias.some(
                                                deficiencia => deficiencia.codigoTipoDeficiencia === e.id
                                            );

                                            return (
                                                <Styled.Row key={e.id}>
                                                    <Styled.CheckBox
                                                        type="checkbox"
                                                        name="deficiencia"
                                                        id={`deficiencia-${idx}`}
                                                        checked={isChecked}
                                                        onChange={evt => handleCheckboxChange(e, isChecked)}
                                                    />
                                                    <Styled.LabelCheck htmlFor={`deficiencia-${idx}`}>{e.descricao}</Styled.LabelCheck>
                                                </Styled.Row>
                                            );
                                        })}
                                    </Styled.TwoColumns>
                                    {otherDeficiencia && (
                                        <Styled.Row error={!!erros.erroDeficienciaOutra}>
                                            <Styled.Label error={!!erros.erroDeficienciaOutra}>Informe qual é a deficiência:</Styled.Label>
                                            <Styled.Column>
                                                <FormikTextInput
                                                    size={455}
                                                    name="deficienciaOutra"
                                                    key={`deficienciaOutra-${erros.erroDeficienciaOutra}`}
                                                    placeholder="Descreva"
                                                    formik={formik}
                                                    onBlurHandler={() => {}}
                                                    onChangeHandler={(evt: any) => {
                                                        formik.setFieldValue('deficienciaOutra', evt.target.value);
                                                        handleDescription(evt.target.value);
                                                    }}
                                                />
                                                <label style={{ marginTop: '5px' }}>Digite no mínimo 5 caractéres</label>
                                            </Styled.Column>
                                        </Styled.Row>
                                    )}
                                </Styled.Column>
                            )}

                            <br />
                            <br />
                            <input type="email" style={{ visibility: 'hidden' }} />

                            <Styled.Row>
                                <PrimaryButton
                                    style={{ padding: '10px 10px 10px 10px' }}
                                    type="submit"
                                    onClick={e => onSubmitHandler(e, formik)}
                                    disabled={submitting}
                                >
                                    {submitting ? 'Enviando...' : 'Continuar'}
                                </PrimaryButton>

                                {getSomeError(formik.values) && (
                                    <Styled.ErrorText>Atenção: preencha todos os campos antes de avançar.</Styled.ErrorText>
                                )}
                            </Styled.Row>

                            {showDialog && <InfoDialog />}
                        </form>
                    );
                }}
            </Formik>
            <PopupQuestion
                onButton={() => setShowErrorModal(false)}
                okButtonText={'Ok'}
                cancelButtonText={'Ok'}
                infoOnly={true}
                question={'O CPF informado já possui uma conta cadastrada em nossa base de dados.'}
                visible={showErrorModal}
            />
        </Styled.Container>
    );
};

export default InformacoesPessoaisForm1;
