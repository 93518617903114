import React, { ReactText, useEffect, useState } from 'react';
import Styled from './AdminGestaoCertificadosUsuarios.styled';
import { Divider } from 'pages/profile/components/identificacaoImagem/components/ImageSelectMobile.styled';
import { YellowButton } from 'components/UI/Buttons';
import { useHistory, useParams } from 'react-router-dom';
import { DatePicker, Radio, Select, Table, TableColumnsType } from 'antd';
import { UsuariosCertificadosService } from 'core/http/service/UsuariosCertificados.service';
import { UsuariosCertificadosModel } from 'models/certificacoes/UsuariosCertificadosModel';
import { SearchProps } from 'antd/es/input';
import Search from 'antd/es/input/Search';
import { MdCheckBox } from 'react-icons/md';
import { IoMdHelpCircleOutline } from 'react-icons/io';
import { IoReload } from 'react-icons/io5';
import moment from 'moment';
import { CertificacoesModel } from 'models/certificacoes/CertificacoesModel';
import { ToastError } from 'components/Toaster';
import { ProfileService } from 'core/http/service/Profile.service';
import LocalidadeDTO from 'models/perfil/LocalidadeDTO';
import EstadoDTO from 'models/perfil/EstadoDTO';
import useFetch from 'hooks/useFetch';

const { RangePicker } = DatePicker;

const formatCPF = (cpf: string): string => {
    if (!cpf) return '';
    return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
};

const EmitedStatus: React.FC<{ emited: boolean }> = ({ emited }) => {
    return (
        <span>
            {emited ? (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '0.5rem',
                    }}
                >
                    <MdCheckBox size={18} style={{ color: 'green' }} />
                    Emitido
                </div>
            ) : (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '0.5rem',
                    }}
                >
                    <IoMdHelpCircleOutline size={18} style={{ color: 'orange' }} />
                    Pendente
                </div>
            )}
        </span>
    );
};

const columns: TableColumnsType<UsuariosCertificadosModel> = [
    {
        title: 'Nome',
        dataIndex: 'nome',

        onFilter: (value, record) => record.nome.indexOf(value as string) === 0,
        sorter: (a, b) => a.nome.length - b.nome.length,
        sortDirections: ['descend'],
    },
    {
        title: 'CPF',
        dataIndex: 'cpf',
        render: (cpf: string) => formatCPF(cpf),
    },
    {
        title: 'E-mail',
        dataIndex: 'email',
    },
    {
        title: 'Estado',
        dataIndex: 'estado',
    },
    {
        title: 'Município',
        dataIndex: 'municipio',
    },
    {
        title: 'Status',
        dataIndex: 'emited',
        key: 'status',
        filters: [
            {
                text: 'Pendente',
                value: 'pendente',
            },
            {
                text: 'Emitido',
                value: 'emitido',
            },
        ],
        onFilter: (value, record) => {
            if (value === 'emitido') {
                return record.emited === true;
            }
            if (value === 'pendente') {
                return record.emited === false;
            }
            return true;
        },
        render: emited => <EmitedStatus emited={emited} />,
    },
    {
        title: 'Data de Solicitação',
        dataIndex: 'dataCreate',
        render: (dataCreate: string) => moment(dataCreate).format('DD/MM/YYYY HH:mm:ss'),
    },
];

const AdminGestaoCertificadosUsuarios = () => {
    const history = useHistory();
    const [filteredUsers, setFilteredUsers] = useState<UsuariosCertificadosModel[]>([]);
    const [listUsers, setListUsers] = useState<UsuariosCertificadosModel[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [dateRange, setDateRange] = useState<string[]>([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState<ReactText[]>([]);
    const [dadosCertificado, setDadosCertificado] = useState<CertificacoesModel>();
    const [cidades, setCidades] = useState<LocalidadeDTO[]>([]);
    const [estados, setEstados] = useState<any[]>([]);

    const [loadingMunicipio, setLoadingMunicipio] = useState<boolean>(false);

    const [searchNome, setSearchNome] = useState('');
    const [searchCpf, setSearchCpf] = useState('');
    const [searchEmail, setSearchEmail] = useState('');
    const [searchEstado, setSearchEstado] = useState('nenhum');
    const [searchMunicipio, setSearchMunicipio] = useState('Selecione um município');

    const profileService = new ProfileService();
    const service = new UsuariosCertificadosService();

    const [filter, setFilter] = useState({
        filterNome: '',
        filterCpf: '',
        filterEmail: '',
        filterEstado: '',
        filterMunicipio: '',
    });

    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedRowKeys: ReactText[], selectedRows: any) => {
            setSelectedRowKeys(selectedRowKeys);
        },
    };

    const atualizarTodosUsuarios = async (ids: any[]) => {
        await service.atualizarTodosUsersEmited(ids);
        await fetchListAll();
    };

    const handleDownloadXLS = () => {
        const selectedUsers = filteredUsers.filter((user: UsuariosCertificadosModel) => {
            if (selectedRowKeys === null || user.codigo === null) {
                return false;
            } else {
                return selectedRowKeys.includes(user.codigo);
            }
        });

        let xlsData: any = [];

        if (selectedUsers.length > 0) {
            xlsData = selectedUsers.map(user => ({
                Nome: user.nome,
                CPF: formatCPF(user.cpf),
                Email: user.email,
                Estado: user.estado,
                Municipio: user.municipio,
            }));
        } else {
            xlsData = filteredUsers.map(user => ({
                Nome: user.nome,
                CPF: formatCPF(user.cpf),
                Email: user.email,
                Estado: user.estado,
                Municipio: user.municipio,
            }));
        }

        const listUserAtt = selectedUsers.map(user => user.codigo);

        const xmlHeader = `<?xml version="1.0"?>
        <?mso-application progid="Excel.Sheet"?>
        <Workbook xmlns="urn:schemas-microsoft-com:office:spreadsheet"
                  xmlns:o="urn:schemas-microsoft-com:office:office"
                  xmlns:x="urn:schemas-microsoft-com:office:excel"
                  xmlns:ss="urn:schemas-microsoft-com:office:spreadsheet"
                  xmlns:html="http://www.w3.org/TR/REC-html40">`;

        const xmlStyles = `<Styles>
            <Style ss:ID="Default" ss:Name="Normal">
                <Alignment ss:Vertical="Bottom"/>
                <Borders/>
                <Font/>
                <Interior/>
                <NumberFormat/>
                <Protection/>
            </Style>
            <Style ss:ID="s62">
                <Font x:Family="Swiss" ss:Bold="1"/>
            </Style>
        </Styles>`;

        const xmlWorksheetStart = `<Worksheet ss:Name="Users">
            <Table>
                <Row>
                    <Cell ss:StyleID="s62"><Data ss:Type="String">Nome</Data></Cell>
                    <Cell ss:StyleID="s62"><Data ss:Type="String">CPF</Data></Cell>
                    <Cell ss:StyleID="s62"><Data ss:Type="String">Email</Data></Cell>
                    <Cell ss:StyleID="s62"><Data ss:Type="String">Estado</Data></Cell>
                    <Cell ss:StyleID="s62"><Data ss:Type="String">Municipio</Data></Cell>
                </Row>`;

        const xmlRows = xlsData
            .map(
                (user: any) => `
            <Row>
                <Cell><Data ss:Type="String">${user.Nome}</Data></Cell>
                <Cell><Data ss:Type="String">${user.CPF}</Data></Cell>
                <Cell><Data ss:Type="String">${user.Email}</Data></Cell>
                <Cell><Data ss:Type="String">${user.Estado}</Data></Cell>
                <Cell><Data ss:Type="String">${user.Municipio}</Data></Cell>
            </Row>`
            )
            .join('');

        const xmlWorksheetEnd = `</Table>
        </Worksheet>`;

        const xmlFooter = `</Workbook>`;

        const xmlContent = xmlHeader + xmlStyles + xmlWorksheetStart + xmlRows + xmlWorksheetEnd + xmlFooter;

        const blob = new Blob([xmlContent], { type: 'application/vnd.ms-excel' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${dadosCertificado?.nome}-${new Date().getMilliseconds()}-${new Date().getSeconds()}.xls`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        atualizarTodosUsuarios(listUserAtt);
    };

    const goToBack = () => history.push('/admin/gestao-certificados');
    const { id } = useParams<{ id: string }>();

    const onSearchNome: SearchProps['onSearch'] = (value, _e, info) => {
        setFilter({ ...filter, filterNome: value });
        setSearchNome(value);
    };

    const onSearchCpf: SearchProps['onSearch'] = (value, _e, info) => {
        setFilter({ ...filter, filterCpf: value });
        setSearchCpf(value);
    };

    const onSearchEmail: SearchProps['onSearch'] = (value, _e, info) => {
        setFilter({ ...filter, filterEmail: value });
        setSearchEmail(value);
    };

    const onSearchEstado: SearchProps['onSearch'] = (value, _e, info) => {
        setFilter({ ...filter, filterEstado: value });
        setSearchEstado(value);
    };

    const onSearchMunicipio: SearchProps['onSearch'] = (value, _e, info) => {
        setFilter({ ...filter, filterMunicipio: value });
        setSearchMunicipio(value);
    };

    const handleRangePickerChange = (dates: any, dateStrings: any) => {
        if (!dates) {
            setDateRange([]);
        } else {
            setDateRange(dateStrings);
        }
    };

    const fetchListAll = async () => {
        try {
            setLoading(true);
            const response = await service.listarAll(parseInt(id));
            const data = response.data;

            const dataWithKeys = data.usuarios.map((item: UsuariosCertificadosModel, index: number) => ({
                ...item,
                key: item.codigo || index,
            }));

            setListUsers(dataWithKeys);
            setDadosCertificado(data.certificacao);
        } catch (error) {}

        setLoading(false);
    };

    const resetFilter = () => {
        setFilter({ filterNome: '', filterCpf: '', filterEmail: '', filterEstado: '', filterMunicipio: '' });
        setDateRange([]);
        setFilteredUsers(listUsers);
        setSearchNome('');
        setSearchCpf('');
        setSearchEmail('');
        setSearchEstado('nenhum');
        setSearchMunicipio('Selecione um município');
    };
    useEffect(() => {
        setFilteredUsers(applyFilters(listUsers, filter));
    }, [filter, listUsers, dateRange]);

    const applyFilters = (users: UsuariosCertificadosModel[], filter: any) => {
        if (!filter) {
            return users;
        }

        return users.filter(user => {
            const matchNome = user.nome.toLowerCase().includes(filter.filterNome.toLowerCase());
            const matchCpf = user.cpf.includes(filter.filterCpf);
            const matchEmail = user.email.toLowerCase().includes(filter.filterEmail.toLowerCase());
            const matchEstado = (user.estado ?? '').toLocaleLowerCase().includes(filter.filterEstado.toLowerCase());
            const matchMunicipio = (user.municipio ?? '').toLocaleLowerCase().includes(filter.filterMunicipio.toLowerCase());

            let matchDate = true;

            if (dateRange.length > 0) {
                const formattedStartDate = moment(dateRange[0], 'DD-MM-YYYY').startOf('day');
                const formattedEndDate = moment(dateRange[1], 'DD-MM-YYYY').endOf('day');
                const userDate = moment(user.dataCreate);

                matchDate = userDate.isSameOrAfter(formattedStartDate) && userDate.isSameOrBefore(formattedEndDate);
            }

            return matchNome && matchCpf && matchEmail && matchEstado && matchMunicipio && matchDate;
        });
    };

    const fetchEstados = async () => {
        const response = await profileService.findAllEstados();

        setEstados(response.data);
    };

    useEffect(() => {
        setListUsers([]);
        fetchListAll();
    }, [id]);

    useFetch(
        async () => {
            const response = await profileService.consultarCidadesPorUF(searchEstado);

            setCidades(response.data);
        },
        [searchEstado],
        setLoadingMunicipio
    );

    useEffect(() => {
        fetchEstados();
    }, []);

    return (
        <Styled.Container>
            <Styled.Column style={{ gap: '0.5rem' }}>
                <Styled.ButtonGoBack onClick={goToBack}>Voltar</Styled.ButtonGoBack>
                <Styled.RowBetween>
                    <Styled.Title>Certificação: {dadosCertificado?.nome}</Styled.Title>
                    <YellowButton
                        onClick={handleDownloadXLS}
                        style={{
                            width: '200px',
                            height: '40px',
                        }}
                    >
                        Gerar relatório
                    </YellowButton>
                </Styled.RowBetween>
            </Styled.Column>
            <Divider
                style={{
                    margin: '10px',
                }}
            />
            <Styled.Column style={{ gap: '0.5rem' }}>
                <Styled.RowBetween>
                    <Styled.Row style={{ gap: '0.5rem' }}>
                        <RangePicker
                            placeholder={['Inicio', 'Fim']}
                            format={'DD-MM-YYYY'}
                            style={{ width: '250px' }}
                            onChange={handleRangePickerChange}
                        />
                        <Search
                            placeholder="Pesquisar por nome"
                            onSearch={onSearchNome}
                            value={searchNome}
                            onChange={e => setSearchNome(e.target.value)}
                            style={{ width: 200, marginRight: 8 }}
                        />
                        <Search
                            placeholder="Pesquisar por CPF"
                            onSearch={onSearchCpf}
                            value={searchCpf}
                            onChange={e => setSearchCpf(e.target.value)}
                            style={{ width: 200, marginRight: 8 }}
                        />
                        <Search
                            placeholder="Pesquisar por E-mail"
                            onSearch={onSearchEmail}
                            value={searchEmail}
                            onChange={e => setSearchEmail(e.target.value)}
                            style={{ width: 200, marginRight: 8 }}
                        />
                    </Styled.Row>
                    <Select
                        showSearch
                        style={{ width: 120 }}
                        defaultValue="nenhum"
                        value={searchEstado}
                        placeholder="Pesquisar por Estados"
                        onChange={v => onSearchEstado(v)}
                        options={[
                            { label: 'Selecione um estado', value: 'nenhum' },
                            ...estados.map(dados => ({ label: dados.nome, value: dados.id })),
                        ]}
                    />

                    <Select
                        showSearch
                        disabled={searchEstado == 'nenhum'}
                        loading={loadingMunicipio}
                        style={{ width: 200 }}
                        placeholder="Search to Select"
                        optionFilterProp="label"
                        value={searchMunicipio}
                        onChange={v => onSearchMunicipio(v)}
                        filterSort={(optionA, optionB) =>
                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                        }
                        options={cidades.map(dados => ({ label: dados.nome, value: dados.nome }))}
                    />
                    <Styled.ButtonFilterReset onClick={resetFilter}>
                        <IoReload />
                        <p>Limpar Filtros</p>
                    </Styled.ButtonFilterReset>
                </Styled.RowBetween>
                <div>
                    <Divider />

                    <Table
                        rowSelection={{
                            type: 'checkbox',
                            ...rowSelection,
                        }}
                        loading={loading}
                        columns={columns}
                        dataSource={filteredUsers}
                    />
                </div>
            </Styled.Column>
        </Styled.Container>
    );
};

export default AdminGestaoCertificadosUsuarios;
