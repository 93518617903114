import React, { useEffect, useState } from 'react';
import GlobalStyled from '../../Solucoes.styled';
import DynamicHeader from 'components/dynamic_header/DynamicHeader';
import Overview from 'pages/solucoes/components/generics/Overview';
import InPractice, { TypeCard } from 'pages/solucoes/components/generics/InPractice';
import SupportMaterial from 'pages/solucoes/components/generics/SupportMaterial';
import FormSolucoes from 'pages/solucoes/components/solucoes/FormSolucoes';
import TipoSolucoesEnum from 'enums/TipoSolucoesEnum';
import ImagemLogo from '../../assets/dialogoSocioemocional/logo_01.png';

//Material Circuito 360
import imagem_01 from '../../assets/dialogoSocioemocional/material/imagem_01.jpg';

//Material InPratice
import imagem_inpratice_01 from '../../assets/dialogoSocioemocional/inPratice/img_01.jpg';
import imagem_inpratice_02 from '../../assets/dialogoSocioemocional/inPratice/img_02.jpg';
import imagem_inpratice_03 from '../../assets/dialogoSocioemocional/inPratice/img_03.jpg';
import imagem_inpratice_04 from '../../assets/dialogoSocioemocional/inPratice/img_04.jpg';
import imagem_inpratice_05 from '../../assets/dialogoSocioemocional/inPratice/img_05.jpg';
import imagem_inpratice_06 from '../../assets/dialogoSocioemocional/inPratice/img_06.jpg';

import ContactContainer from 'pages/home/components/contact/ContactContainer';
import useFetch from 'hooks/useFetch';
import ResgatedToken from 'pages/solucoes/components/solucoes/ResgatedToken';
import { validAccessExclusivo } from 'pages/solucoes/helpers/FunctionsHelpersSolucoes';

const DialogoSocioemocional = () => {
    const [openModalToken, setOpenModalToken] = useState<boolean>(false);
    const [access, setAccess] = useState<boolean>(false);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useFetch(async () => {
        const status = await validAccessExclusivo();
        setAccess(status);
    }, []);

    return (
        <div>
            <DynamicHeader />
            <GlobalStyled.Container>
                <Overview
                    dados={{
                        title: 'Diálogos Socioemocionais',
                        descricao: `
                        Em 2016, após muitas pesquisas sobre o tema de competências socioemocionais, a equipe pedagógica do Instituto Ayrton Senna, em parceria com os seus pesquisadores do eduLab21, desenvolveram a solução Diálogos Socioemocionais. 
                        A solução passou por atualizações em 2024, trazendo materiais ainda mais completos para as redes de ensino.
                        `,
                        objetivo: `
                          O objetivo do Diálogos Socioemocionais é contribuir com a construção de
                            políticas públicas de desenvolvimento de competências socioemocionais que
                            garantam a educação integral dos estudantes brasileiros (modalidades Projeto de Vida
                            e Duplo Foco).
                         `,
                        publicoAlvo: '6º ao 9º do Ensino Fundamental e Ensino Médio.',
                        avaliacao: 'Instrumento de avaliação socioemocional rubricas e instrumento Senna.',
                        formacao: `
                         (carga horária – 16 horas).
                        `,
                        acompanhamento: `
                            Dados quantitativos e qualitativos de acompanhamento e
                            relatórios de devolutiva do instrumento de avaliação.
                        `,
                        atores: `
                           Estudante, Professor (gestão da sala de aula: ensino/ aprendizagem),
                            Coordenador Pedagógico / Diretor (gestão da rotina escolar), Secretaria de Educação
                            (gestão da política educacional).
                        `,
                        elementos: `
                          (I) Articulação com políticas de educação integral, favorece o
                            desenvolvimento socioemocional dos estudantes, impactando também o desempenho
                            acadêmico e outras esferas da vida; (II) Foco em práticas e vivências, com uso de
                            metodologias ativas e da avaliação formativa ; (III) Promoção de letramento e
                            desenvolvimento socioemocional; (IV) Base em evidências, matriz de competências
                            abrangente e robusta; e (V) Professor como mediador do desenvolvimento integral.
                        
                        `,
                        img: ImagemLogo,
                        linkExclusivo: '/exclusivo/dialogos-socioemocionais',
                    }}
                />
                <InPractice
                    openModalToken={() => setOpenModalToken(true)}
                    linkExclusivo="/exclusivo/dialogos-socioemocionais"
                    items={[
                        {
                            type: TypeCard.VIDEO,
                            url: 'T9LypYkUmZY',
                        },
                        {
                            type: TypeCard.VIDEO,
                            url: 'AAgSVugr4J4',
                        },
                        {
                            type: TypeCard.IMAGEM,
                            url: imagem_inpratice_03,
                            credits: {
                                credits: 'Aurelio Alves',
                                local: 'Sobral',
                                ano: '2024',
                            },
                        },
                        {
                            type: TypeCard.IMAGEM,
                            url: imagem_inpratice_04,
                            credits: {
                                credits: 'Aurelio Alves',
                                local: 'Sobral',
                                ano: '2024',
                            },
                        },
                        {
                            type: TypeCard.IMAGEM,
                            url: imagem_inpratice_05,
                            credits: {
                                credits: 'Aurelio Alves',
                                local: 'Sobral',
                                ano: '2024',
                            },
                        },
                        {
                            type: TypeCard.IMAGEM,
                            url: imagem_inpratice_06,
                            credits: {
                                credits: 'Aurelio Alves',
                                local: 'Sobral',
                                ano: '2024',
                            },
                        },
                        {
                            type: TypeCard.IMAGEM,
                            url: imagem_inpratice_01,
                            credits: {
                                credits: 'Aurélio Alves',
                                local: 'Fortaleza',
                                ano: '2022',
                            },
                        },
                        {
                            type: TypeCard.IMAGEM,
                            url: imagem_inpratice_02,
                            credits: {
                                credits: 'Aurélio Alves',
                                local: 'Fortaleza',
                                ano: '2022',
                            },
                        },
                    ]}
                    description={`
                    Quer conhecer um pouco mais sobre como o Diálogos Socioemocionais acontece na prática?<br/><br/>
                    Navegue pelas imagens e vídeos e conheça algumas das redes onde a solução é implementada atualmente.<br/><br/>
                    Sua rede pode ser a próxima a implementar a solução Diálogos Socioemocionais e ter as imagens compartilhadas aqui na humane. Que tal? 
            
                    `}
                />
                <SupportMaterial
                openModalToken={() => setOpenModalToken(true)}
                    linkExclusivo="/exclusivo/dialogos-socioemocionais"
                    isBorderBlue
                    description="
                 Conheça os materiais pedagógicos disponibilizados do Diálogos Socioemocionais: Instrumento de Rubricas para avaliação formativa de competências socioemocionais; 
                 Caderno Modelo Pedagógico, que organiza e apresenta os fundamentos teóricos e metodológicos da proposta; Banco de planejamentos docentes inspiracionais de diversos componentes curriculares;
                  Caderno de Orientações à Gestão, que apresenta a proposta Diálogos Socioemocionais em linhas gerais, seus materiais, as estratégias de implementação nas redes de ensino, as ações de acompanhamento e 
                  monitoramento previstas, os papéis e responsabilidades dos atores envolvidos; Caderno da Sistemática de Monitoramento e Acompanhamento e Caderno de Orientações sobre avaliação Socioemocional, 
                  que apresenta orientações para avaliação de competências socioemocionais, os instrumentos SENNA e de Rubricas.
                "
                    img={imagem_01}
                    slideToScrool={4}
                />

                <FormSolucoes
                    isBlue
                    tipoSolucao={TipoSolucoesEnum.DIALOG_SOCIOEMOCIONAL}
                    preDefinition
                    setOpenModalToken={setOpenModalToken}
                    accessExclusivo={access}
                    linkExclusivo="/exclusivo/dialogos-socioemocionais"
                />
                <ContactContainer isLight />
                <ResgatedToken valueModalToken={openModalToken} setHidenModalToken={setOpenModalToken} />
            </GlobalStyled.Container>
        </div>
    );
};

export default DialogoSocioemocional;
